import { useState } from 'react';
import { useParams } from "react-router-dom";

import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

import products from "../assets/products.json";
import BuyModal from '../components/BuyModal';


const SingleProduct = () => {
  const { product_id } = useParams();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const product = products[product_id];

  return (
    <div className="App">
      <Container fluid className="vh-70 d-flex flex-column justify-content-start">
        <Row className="w-100 h-100 d-flex align-items-start">
          {/* Product Image (Left Section) */}
          <Col md={5} className="d-flex justify-content-center align-items-center">
            <Card.Img
              src={`/assets/images/products/${product.image}`}
              alt={product.name}
              className="img-fluid w-80 h-90"
              style={{

              }}
            />
          </Col>

          {/* Product Details (Right Section) */}
          <Col md={7} className="d-flex flex-column justify-content-center px-5 text-start">
            <h1 className="text-dark mb-1" style={{ fontSize: '3rem', fontWeight: 'bold' }}>
              {product.name}
            </h1>
            <h5 className="text-muted mb-2" >Pris: {product.price}</h5>
            <div
              variant="primary"
              size="lg"
              className="mb-4 square-button"
              onClick={handleShow}
            >
              Kjøp
            </div>
            {/* Product Description */}
            <div className="mb-4">
              <h5 className="text-dark" style={{ fontSize: '1.5rem' }}>Beskrivelse</h5>
              <p className="text-dark" >
                {product.description}
              </p>
            </div>

            {/* Additional Features or Details */}
            <div className="mb-4">
              <h5 className="text-dark" style={{ fontSize: '1.5rem' }}>Egenskaper</h5>
              <ul style={{ fontSize: '0.9rem', paddingLeft: '20px' }}>
                {product.features.map((feature, index) => (
                  <li key={index}>{feature}</li>
                ))}
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
      <BuyModal show={show} handleClose={handleClose} />
    </div>
  );
}

export default SingleProduct;
