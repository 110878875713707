import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import SmallProduct from "../components/SmallProduct/SmallProduct";

import products from "../assets/products.json";


const Products = () => {
  console.log(products)
  return (
    <div className="App">
      <Container>
        <Row>
          {products.map((product, product_id) => {
            return (
              <Col sm={12} md={6} lg={4} className='mb-4'>
                <SmallProduct product_id={product_id} name={product.name} price={product.price} imageName={product.image} />
              </Col>
            )
          })}
        </Row>
      </Container>
    </div>
  );
}

export default Products;
