import '../assets/styles/header.css';
import '../assets/styles/body.css';
import '../assets/styles/footer.css';
import '../index.css';

import { Outlet } from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Col, Row } from 'react-bootstrap';



const Layout = () => {
    return (
        <>
            <Navbar expand="lg" className="bg-body-tertiary background-header">
                <Container fluid className='mx-10'>
                    <Navbar.Brand href="/">
                        <img src="/logo.png" alt="Logo" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end bg-body-tertiary">
                        <Nav className="justify-content-end text-end" activeKey={window.location.pathname}>
                            <Nav.Item>
                                <Nav.Link href="/">
                                    Hjem
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link href="/products">
                                    Ved
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link href="/delivery">
                                    Frakt
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link href="/about">
                                    Om oss
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link href="/contact">
                                    Kontakt oss
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <div id="top" className='content mx-2 my-1'>
                <Outlet />
            </div>

            <footer>
                <Container fluid>
                    <Row>
                        <Col>
                            <div className="logo">
                                <img src="assets/images/logo_transparent.png" alt="V365 logo" />
                            </div>
                            <ul>
                                <li><p>Hytta i skauen</p></li>
                                <li><a href="#">butikk@v365.com</a></li>
                            </ul>
                        </Col>
                        <Col>
                        </Col>
                        <Col>
                            <h4>Produkter</h4>
                            <ul>
                                <li><a href="/products">Ved</a></li>
                                <li><a href="/delivery">Frakt</a></li>
                            </ul>
                        </Col>
                        <Col>
                            <h4>Nyttige lenker</h4>
                            <ul>
                                <li><a href="/">Hjem</a></li>
                                <li><a href="/about">Om oss</a></li>
                                <li><a href="/contact">Kontakt oss</a></li>
                            </ul>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="under-footer">
                                <p>Copyright © 2024 V365. Alle rettigheter vedtatt!

                                    <br />Design: <a href="https://www.youtube.com/watch?v=PZgdxmINq8Y" target="_parent" title="Avansert design">Huggerud & co. Design</a></p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </>
    )
};

export default Layout;