import { useState } from 'react';

import './SmallProduct.css';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faShoppingCart } from '@fortawesome/free-solid-svg-icons'

import BuyModal from '../BuyModal';

const SmallProduct = ({ product_id, name, price, imageName }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (event) => {
    event.preventDefault()
    setShow(true);
  };
    return (
        <a href={`product/${product_id}`}>
            <div className="small-product">
                <div className="thumb">
                    <div className="hover-content">
                        <ul>
                            <li><a><i><FontAwesomeIcon icon={faEye} /></i></a></li>
                            <li><a onClick={event => handleShow(event)}><i><FontAwesomeIcon icon={faShoppingCart} /></i></a></li>
                        </ul>
                    </div>
                    <img src={`assets/images/products/${imageName}`} alt="" />
                </div>
                <div className="down-content">
                    <h4>{name}</h4>
                    <span>{price}</span>
                </div>
            </div>
            <BuyModal show={show} handleClose={handleClose} />
        </a>
    )
}

export default SmallProduct