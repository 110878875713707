import './Carousel.css';

import Slider from "react-slick";
import CarouselItem from "./CarouselItem";

import products from "../../assets/products.json";


const Carousel = () => {
    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2.5,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1.7,
                    arrows: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1.5,
                    arrows: false
                }
            }
        ]

    };

    return (
        <Slider {...settings}>
            {products.map((product, product_id) => {
                if( product.best_seller){
                    return <CarouselItem product_id={product_id} name={product.name} price={product.price} imageName={product.image} />
                }
            })}
        </Slider>
    );
}

export default Carousel;
