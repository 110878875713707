import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

const BuyModal = ({show, handleClose}) => {
    // const handleClose = () => setShow(false);
    // console.log(setShow)

    return (
        <Modal show={show} onHide={handleClose} size="lg" >
            <Modal.Header closeButton>
                <Modal.Title>Takk for at du ønsker å kjøpe dette produktet</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p style={{ overflowWrap: "break-word" }}>Vår nettbutikk har dessverre ikke implementert annonym betaling enda, og derfor benytter vi kun bitcoin. Vennligst overfør til vår wallet <strong>YmMxcTduMG43MHg0NjM3bXh1cTZtc2s1a2VxdGp6ZHI3M2g3d3c1N201IA==</strong> og oppgi hvilke produkter du ønsker å kjøpe.</p>
            </Modal.Body>
            <Modal.Footer>
                <div
                    variant="primary"
                    className="mb-2 square-button"
                    style={{ width: '60px' }}
                    onClick={handleClose}
                >
                    Lukk
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default BuyModal;