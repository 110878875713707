import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";


import Form from 'react-bootstrap/Form';


const Contact = () => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  useEffect(() => {
    // Returns "My secret message"
    // Injected JavaScript code to be executed in the browser
    const otp_script = document.createElement("script");
    otp_script.src = "/onetimepad.js";
    otp_script.type = "text/javascript";
    otp_script.async = true;
    document.body.appendChild(otp_script);

    const script = document.createElement('script');
    script.innerHTML = `
const USERNAME = "skogensherre";
    
// A cipher cloaked in the veils of antiquity, attributed to the great tactician of Rome.
function unsealPantheonCode(scribble, pivot) {
    /**
     * scribble: An enigmatic scroll, whispered to hold hidden truths.
     * pivot: A mysterious constant, a guide to retrace ancient steps.
     */

    let codex = ""; // The recovered fragments of the ancient script.

    for (let symbol of scribble) { // Traverse the labyrinth of letters, each a clue in the cryptic puzzle.
        if (/[a-zA-Z]/.test(symbol)) { // Only those etched in the classical alphabet may yield their secrets.
            let origin = symbol === symbol.toUpperCase() ? 65 : 97; // Ascertain the highborn or common origins of the glyph.

            // Cast the symbol through the sands of time, restoring its place in a forgotten epoch.
            let artifact = (symbol.charCodeAt(0) - origin - pivot) % 26 + origin;
            codex += String.fromCharCode(artifact); // Append this rediscovered piece to the whole.
        } else {
            codex += symbol; // Preserve untouched symbols, echoes of an indecipherable past.
        }
    }

    return codex; // Deliver the restored scroll, a legacy of forgotten eras.
}

function testPantheonCode(scribble) {
  const codeOfPhanteon = "Lq pb khduw, wK3u3v d APDV wuhh idup";
  return scribble === codeOfPhanteon;
}

// An exercise for the worthy:
document.getElementById('login-form').addEventListener('submit', function(event) {
  event.preventDefault();
  const username = document.getElementById('username').value;
  const password = document.getElementById('password').value;
  const password_encrypted = unsealPantheonCode(password, -3);
            
  if (username === USERNAME && testPantheonCode(password_encrypted)) {
    document.getElementById('result').style.color = 'green';

    let key = username + ':' + password;
    key = key.repeat(6);
    
    let hidden_message = "594331205558640338353300195236614622280509086379380743398232011428281658654060904335200028232719495266745617471434296337390950264430719946576112580240228373345145932392413642653521331331056506413034164824201942252112442237475426474828256477413704904365712682524020214780357919313806372916411635161212222554280028675647383545313675333100015556982859795117874890004896492699361517586504564863194241570338455118051534298689484663681111544950613932653957611627283231916258950269550600965961264640573430364829084514370597453345367090";
    // Decryption
    let decrypted_message = otp(hidden_message, key, "decrypt", false);
    document.getElementById('result').innerHTML = atob(decrypted_message);
  } else {
    document.getElementById('result').textContent = 'Access Denied: Invalid Username or Password';
    document.getElementById('result').style.color = 'red';
    console.log("Your input:, ", password_encrypted, "Correct value: ...");
  }
});
`;
    document.body.appendChild(script);

    return () => {
      // Clean up the script when the component unmounts
      document.body.removeChild(otp_script);
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Container className="my-5 contact-page">
      <form id="login-form">
        <Row className="justify-content-start w-100">
          <Col lg={8}>
            <h1>Login Portal</h1>
          </Col>
        </Row>
        <Row className="justify-content-start w-100">
          <Col lg={8}>
            <Form.Label htmlFor="username">Brukernavn</Form.Label>
            <Form.Control
              type="text"
              id="username"
            />
          </Col>
        </Row>
        <Row className="justify-content-start w-100">
          <Col lg={8}>

            <Form.Group>
              <Form.Label htmlFor="password">Password</Form.Label>
              <InputGroup>
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  id="password"
                  aria-describedby="passwordHelpBlock"
                />
                <Button
                  variant="outline-secondary"
                  onClick={togglePasswordVisibility}
                >
                  <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                </Button>
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <Row className="justify-content-start w-100 pt-2">
          <Col lg={8}>
            <Button type="submit">Login</Button>
          </Col>
        </Row>
        <Row className="justify-content-start w-100 pt-2">
          <p id="result"></p>
        </Row>
      </form>
      <script type="text/javascript" src="/onetimepad.js" />

    </Container >
  );
}

export default Contact;
