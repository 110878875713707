import { Container, Row, Col, ListGroup } from 'react-bootstrap';

const Delivery = () => {
    return (
      <Container className="my-5 delivery">
      <Row className="w-100">
        <Col lg={8}>
          <div>
            <h2 className="mb-4">Frakt og levering – V365</h2>
            <p>
              Hos V365 er det viktig for oss at du får produktene dine levert raskt, trygt og enkelt. Vi tilbyr fleksible leveringsalternativer for å sikre at du får vedproduktene dine akkurat når og hvor du trenger dem.
            </p>

            <h3 className="mt-4">Leveringsalternativer</h3>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <strong>Hjemlevering:</strong>
                <p>Vi leverer rett til døren din, enten du bor i byen eller på landet.</p>
                <ul>
                  <li>Leveringstid: 2–5 virkedager, avhengig av hvor du bor.</li>
                  <li>Våre sjåfører sørger for at veden plasseres trygt og lett tilgjengelig.</li>
                </ul>
              </ListGroup.Item>
              <ListGroup.Item>
                <strong>Hent selv:</strong>
                <p>Du kan hente produktene dine direkte fra vårt lager i Finnskogen.</p>
                <ul>
                  <li>En fleksibel løsning for deg som ønsker å spare på fraktkostnadene.</li>
                  <li>Kontakt oss for å avtale tidspunkt for henting.</li>
                </ul>
              </ListGroup.Item>
              <ListGroup.Item>
                <strong>Ekspresslevering:</strong>
                <p>For deg som trenger ved raskt, tilbyr vi ekspresslevering samme dag (gjelder i utvalgte områder).</p>
                <ul>
                  <li>Kontakt oss for tilgjengelighet og pris.</li>
                </ul>
              </ListGroup.Item>
            </ListGroup>

            <h3 className="mt-4">Fraktkostnader</h3>
            <p>
              Fraktkostnadene beregnes basert på produktmengde og leveringsadresse. Nøyaktig pris vises ved utsjekk i handlekurven.
            </p>
            <p>
              <strong>Gratis frakt på bestillinger over 2.99m₿.</strong>
            </p>

            <h3 className="mt-4">Pålitelige leveranser</h3>
            <p>
              Alle leveranser gjøres av vårt erfarne transportteam eller gjennom pålitelige logistikkpartnere. Vi sikrer at produktene kommer frem i perfekt stand, slik at du kan stole på at veden er klar til bruk med en gang.
            </p>

            <h3 className="mt-4">Spesielle ønsker?</h3>
            <p>
              Hvis du har spesifikke krav, som levering til hytte, utilgjengelige områder, eller behov for hjelp til å stable veden, er det bare å kontakte oss. Vi gjør vårt beste for å imøtekomme dine behov.
            </p>

            <p>
              Ta kontakt med oss via en kryptert meldingstjeneste hvis du har spørsmål om frakt eller levering!
            </p>

            <h3 className="mt-4 mb-0">Få veden levert når du trenger den </h3>
            <h3 className="mt-0 mx-3">– raskt, enkelt og pålitelig med V365!</h3>
          </div>
        </Col>
      </Row>
    </Container>
    );
  }
  
  export default Delivery;
  