import { Container, Row, Col, ListGroup } from 'react-bootstrap';

const Contact = () => {
  return (
    <Container className="my-5 contact-page">
      <Row className="justify-content-start w-100">
        <Col lg={8}>

          <div className="">
            <h2 className="mb-4">Kontakt oss – V365</h2>
            <p>
              Vi i V365 er her for å hjelpe deg med alle spørsmål eller behov du måtte ha. Enten det gjelder bestilling, frakt, eller informasjon om produktene våre, kan du enkelt komme i kontakt med oss. Vi setter kundene våre først og svarer raskt på alle henvendelser.
            </p>

            <h3 className="mt-4">Kontaktinformasjon</h3>
            <ListGroup variant="flush">
              <ListGroup.Item>
                Våre kontortider er:
                <ul>
                  <li>Onsdag: 10:30–11:00</li>
                </ul>
              </ListGroup.Item>
              <ListGroup.Item>
                <strong>✉️ E-post: butikk@v365.com</strong>
                <p>Send oss en e-post og vi svarer innen 24 timer.</p>
              </ListGroup.Item>
              <ListGroup.Item>
                <strong>🏠 Besøksadresse: Hytta i skauen</strong>
                <p>Vårt lager og kontor ligger i hjertet av Finnskogen.</p>
              </ListGroup.Item>
            </ListGroup>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Contact;
