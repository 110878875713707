import { Container, Row, Col, Image } from 'react-bootstrap';


const About = () => {
  return (
    <Container className="my-5 about-us">
      <Row>

        {/* Right Column for Text */}
        <Col md={8}>
          <Row>
            {/* Right Column for Text (Part 1) */}
            <Col xs={12}>
              <h2 className="mb-0">V365: Din pålitelige leverandør av ved, året rundt</h2>
              <p>
                Velkommen til V365 – en unik leverandør av ved, grunnlagt på lidenskap, tradisjon og innovasjon. Selskapet ble etablert av Pål Impinnen, som hentet inspirasjon fra sine røtter i Finnskogen, et område kjent for sine tette skoger og sterke tradisjoner for vedproduksjon.
              </p>
            </Col>
          </Row>

          {/* Row for Image Below on Small Screens (will appear below text on xs screens) */}
          <Row className="d-block d-md-none">
            <Col xs={12} className="d-flex justify-content-center align-items-center my-4">
              <Image
                src="assets/images/paal.png" // Replace with the actual path to the image of Pål
                alt="Pål Impinnen"
                roundedCircle
                fluid
                className="zoom-image"
                style={{ maxWidth: '350px', maxHeight: '350px' }}
              />
            </Col>
          </Row>

          {/* Second Section (header and text) */}
          <Row>
            <Col xs={12}>
              <h3 className="mt-4  mb-0">Fra Finnskogen til V365</h3>
              <p>
                Pål vokste opp med den finske skogsarven, hvor ved ikke bare var en kilde til varme, men også en del av livet og kulturen. Med et ønske om å dele denne stolte tradisjonen startet han V365, en virksomhet som kombinerer gammeldags håndverk med moderne løsninger.
              </p>
              <p>
                Med kjærlighet for skogen og respekt for naturen, har han skapt et selskap som leverer kvalitetsved i alle former – fra askefri ved til kreative produkter som ved med innebygd tennbrikett og julekalendere fylt med kubber.
              </p>
            </Col>
          </Row>

          {/* Third Section (header and text) */}
          <Row>
            <Col>
              <h3 className="mt-4 mb-0">Bærekraftig vedproduksjon</h3>
              <p>
                Finnskogens ånd lever videre i hvert produkt vi tilbyr. Her finner du alt fra klassisk bjørkeved til innovative alternativer som selvantennende ved og beredskapssekker, alltid med fokus på kvalitet og bærekraft.
              </p>
              <p>
                Pål Impinnen har også et sterkt fokus på miljøet og bærekraftig skogsdrift. Alt vi gjør handler om å ta vare på naturen samtidig som vi bringer varme og hygge til våre kunder. Hos V365 handler det ikke bare om ved – det handler om å gi videre en arv som varmer både kropp og sjel.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3 className="mt-4"> Velg V365 for varme, tradisjon og pålitelighet, 365 dager i året.</h3>
            </Col>
          </Row>
        </Col>

        {/* Left Column for Image */}
        <Col md={4} className="d-flex justify-content-center align-items-center d-none d-md-block">
          <Image
            src="assets/images/paal.png" // Replace with the actual path to the image of Pål
            alt="Pål Impinnen"
            roundedCircle
            fluid
            className="zoom-image"
            style={{ maxWidth: '250px', maxHeight: '250px' }}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default About;
