import { useState } from 'react';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faShoppingCart } from '@fortawesome/free-solid-svg-icons'

import BuyModal from '../BuyModal';

const CarouselItem = ({ product_id, name, price, imageName }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <div className='slider-item'>
            <div className="thumb">
                <div className="hover-content">
                    <ul>
                        <li><a href={`product/${product_id}`}><i><FontAwesomeIcon icon={faEye} /></i></a></li>
                        <li><a onClick={handleShow}><i><FontAwesomeIcon icon={faShoppingCart} /></i></a></li>
                    </ul>
                </div>
                <img src={`assets/images/products/${imageName}`} alt="" />
            </div>
            <div className="down-content">
                <h4>{name}</h4>
                <span>{price}</span>
            </div>
            <BuyModal show={show} handleClose={handleClose} />
        </div>
    );
}




export default CarouselItem;
