import '../assets/styles/body.css';

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container } from 'react-bootstrap';
import Carousel from '../components/carousel/Carousel';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faPaperPlane, faQuoteLeft } from '@fortawesome/free-solid-svg-icons'

const Home = () => {
  return (
    <div>
      <Container className="section">
        <Row className='main-banner'>
          <Col sm={12} md={6} className='mb-3'>
            <div className="left-content">
              <div className="thumb">
                <div className="inner-content">
                  <h4>God jul!</h4>
                  <span>Finn julegavene som varmer!</span>
                  <div className="main-border-button">
                    <a href="/products">Kjøp nå!</a>
                  </div>
                </div>
                <img src="/assets/images/christmas.png" alt="" />
              </div>
            </div>
          </Col>
          <Col>
            <div className="right-content">
              <Row>
                <Col>
                  <div className="right-first-image">
                    <div className="thumb">
                      <div className="inner-content">
                        <h4>Ved</h4>
                        <span>Tørreste veden på markedet</span>
                      </div>
                      <div className="hover-content">
                        <div className="inner">
                          <h4>Ved</h4>
                          <p>Brenner som tørt krutt</p>
                          <div className="main-border-button">
                            <a href="/products">Se mer</a>
                          </div>
                        </div>
                      </div>
                      <img src="/assets/images/baner-right-image-01.jpg" />
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="right-first-image">
                    <div className="thumb">
                      <div className="inner-content">
                        <h4>Tradisjoner</h4>
                        <span>Stolte tradisjoner, for din vedsamling</span>
                      </div>
                      <div className="hover-content">
                        <div className="inner">
                          <h4>Tradisjoner</h4>
                          <p>Tradisjoner hugges i ved</p>
                          <div className="main-border-button">
                            <a href="/about">Se mer!</a>
                          </div>
                        </div>
                      </div>
                      <img src="/assets/images/baner-right-image-02.jpg" />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="right-first-image">
                    <div className="thumb">
                      <div className="inner-content">
                        <h4>Frakt</h4>
                        <span>Holder oss på gamle de veiene!</span>
                      </div>
                      <div className="hover-content">
                        <div className="inner">
                          <h4>Frakt</h4>
                          <p>Veden stabler ikke seg selv!</p>
                          <div className="main-border-button">
                            <a href="/delivery">Se mer!</a>
                          </div>
                        </div>
                      </div>
                      <img src="/assets/images/baner-right-image-03.jpg" />
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="right-first-image">
                    <div className="thumb">
                      <div className="inner-content">
                        <h4>Om oss</h4>
                        <span>Utforsk våre stolte tradisjoner!</span>
                      </div>
                      <div className="hover-content">
                        <div className="inner">
                          <h4>Om oss</h4>
                          <p>Ved vokser ikke på trær!</p>
                          <div className="main-border-button">
                            <a href="/about">Les mer</a>
                          </div>
                        </div>
                      </div>
                      <img src="/assets/images/baner-right-image-04.jpg" />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="section">
        <Row>
          <Col>
            <div class="section-heading">
              <h2>Bestselgere</h2>
              <span>Våre brennhete produkter</span>
            </div>
          </Col>
        </Row>
        <Row className='mx-1'>
          <Col>
            <Carousel />
          </Col>
        </Row>
      </Container>

      <Container className="section" id="new-products">
            <Row>
              <Col xl="6" className='mb-4'>
                <div class="left-content">
                  <h2>Utforsk våre produkter</h2>
                  <span>Når det kommer til vedlevering, er Ved365 virkelig i en klasse for seg selv! Med lynrask levering og en kvalitet på veden som får selv de mest kresne vedhuggere til å måpe, settes standarden for hele bransjen.</span>
                  <div class="quote">
                    <FontAwesomeIcon icon={faQuoteLeft} />
                    <p>  
                    Veden er så bra at jeg lurer på om jeg bør bruke den som hovedstrømkilde i huset. Hvem trenger strømregning?
                    </p>
                  </div>
                  <p>Oppdag vår nye kolleksjon, "<b>Askefri Ved</b>", som gir deg maksimal varme med minimalt aske! Denne eksklusive veden er perfekt for deg som ønsker å nyte flammene uten bekymringer – mindre rydding og mer kos! </p>
                  <p>Hver sekk med ved er omhyggelig sortert og tørket for å sikre maksimal brennverdi! Uansett om du skal varme opp hytta, stua eller bare vil ha et koselig bål, kan du stole på at du har den beste veden. Så sett deg tilbake, slapp av, og la oss ordne vedhogsten – du vil ikke angre!</p>
                  <div class="main-border-button">
                    <a href="/products">Se flere produkter</a>
                  </div>
                </div>
              </Col>
              <Col 
                xs="12" 
                xl="6" 
              >
                <div class="right-content">
                  <Row>
                    <Col 
                      xs="12" 
                      md="6" 
                      xl="6"
                      className='d-flex align-items-center justify-content-center'
                    >
                      <div class="upper-left">
                        <h4>Askefri vedkubbe</h4>
                        <span>Nyeste kolleksjon</span>

                        {/*<img src="assets/images/Askefri_vedkubbe.png" alt="" /> */}
                      </div>
                    </Col>
                    <Col 
                      xs="12" 
                      md="6" 
                      xl="6"
                      className='d-flex align-items-center justify-content-center'
                    >
                      <div class="first-image">
                        <img src="assets/images/baal.jpg" alt="" />
                      </div>
                    </Col>
                    <Col 
                      xs={{span: 12, order: "last"}} 
                      md={{span: 6, order: "3"}} 
                      xl={{span: 6, order: "3"}} 
                      className='mb-4 d-flex align-items-center justify-content-center'
                    >
                      <div class="second-image">
                        <img src="assets/images/explore_products.jpg" alt="" />
                      </div>
                    </Col>
                    <Col 
                      xs={{span: 12, order: "3"}} 
                      md={{span: 6, order: "last"}} 
                      xl={{span: 6, order: "last"}} 
                      className="mb-4 d-flex align-items-center justify-content-center full-height"
                    >
                      <div class="types">
                        <h4>Vi brenner for deg</h4>
                        <span>Over 1 337 unike produkter</span>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
      </Container>

      <div className="subscribe section">
        <Container>
          <Row>
            <Col lg="8">
              <div class="section-heading">
                <h2>Anbefal oss til en venn og få en eksklusiv v365 lighter</h2>
                <span>Fordi vennskap er som ved – det trenger litt fyring for å holde varmen!</span>
              </div>
              <form id="subscribe" action="" method="get">
                <Row>
                  <Col lg="5">
                    <fieldset>
                      <input name="name" type="text" id="name" placeholder="Ditt Navn" required="" />
                    </fieldset>
                  </Col>
                  <Col lg="5">
                    <fieldset>
                      <input name="email" type="text" id="email" pattern="[^ @]*@[^ @]*" placeholder="Din Epost" required="" />
                    </fieldset>
                  </Col>
                  <Col lg="2">
                    <fieldset>
                      <button type="submit" id="form-submit" class="main-dark-button"><FontAwesomeIcon icon={faPaperPlane} /></button>
                    </fieldset>
                  </Col>
                </Row>
              </form>
            </Col>
            <Col lg="4">
              <Row>
                <Col>
                  <ul>
                    <li>Butikk:<br /><span>Internett</span></li>
                    <li>Besøksadresse:<br /><span>Hytta i skauen</span></li>
                  </ul>
                </Col>
                <Col>
                  <ul>
                    <li>Kontortid:<br /><span>Hver onsdag:<br /> 1030-1100</span></li>
                    <li>Email:<br /><span>info@ved365.com</span></li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default Home;
